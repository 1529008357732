import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

const initSortableFields = () => {
    const sortableFields = document.getElementById('sortable-fields');
    new Sortable(sortableFields, {
        handle: '.drag-handle',
        ghostClass: "custom-ghost-class",  // Class name for the drop placeholder
        chosenClass: "custom-chosen-class",  // Class name for the chosen item
        dragClass: "custom-drag-class",
        animation: 150,
        onEnd: saveFieldOrder
    });
};

const saveFieldOrder = () => {
    const sortableFields = document.getElementById('sortable-fields');
    const fieldElements = sortableFields.getElementsByClassName('list-item');

    const fieldIds = Array.from(fieldElements).map((fieldElement) => {
        return fieldElement.dataset.fieldId;
    });

    const contactTypeId = document.getElementById('sortable-fields').dataset.id;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch('/contact_types/${contactTypeId}/sort_fields', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({fieldIds: fieldIds}),
    })
        .then((response) => {
            if (response.ok) {
                console.log('Field order saved successfully.');
            } else {
                throw new Error('Error saving field order.');
            }
        })
        .catch((error) => {
            console.error(error);
        });
};

export { initSortableFields }