import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

const initKanbanSortable = (ulElements) => {
    if (!/Mobi|Android/i.test(navigator.userAgent)) {
        const saveKanbanBinded = saveKanban.bind(null, ulElements);
        ulElements.forEach((ul) => {
            new Sortable(ul, {
                group: 'kanban', // set both lists to same group
                animation: 300,
                onEnd: saveKanbanBinded,
                scroll: true,
                ghostClass: "custom-ghost-class",  // Class name for the drop placeholder
                chosenClass: "custom-chosen-class",  // Class name for the chosen item
                filter: "form-div"
            });
        });
    }
};
const kanbanForm = document.querySelector(".kanban-form-input");
const saveKanban = (ulElements) => {
    const kanbanIds = {"columns": []};
    ulElements.forEach(ul => {
        const itemIds = [];
        ul.querySelectorAll(".kanban-col-item")
            .forEach(item => itemIds.push(item.dataset.itemId));
        kanbanIds.columns.push(
            {
                'id': ul.dataset.colId,
                'itemIds': itemIds
            }
        );
    });
    //kanbanForm.value = JSON.stringify(kanbanIds);

    const kanbanId = document.querySelector(".kanban").dataset.id;
    const formData = new FormData();
    formData.append('kanban[kanbanIds]', JSON.stringify(kanbanIds));
    Rails.ajax({
        url: `/kanbans/${kanbanId}/sort`,
        type: "patch",
        data: formData
    })
}
export { initKanbanSortable };