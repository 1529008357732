
window.fetchHistoricCash = function () {
    if (document.querySelector("#historic-cash")) {
        console.log("fetchHistoricCash");
        fetch('/dashboards/historic_cash', {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Accept": "text/javascript"
            }
        })
            .then(response => response.text())
            .then(html => {
                document.querySelector("#historic-cash").innerHTML = html;
            });
    }
}