import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

const initColumnSortable = () => {
    const kanbanCols = document.querySelector('.kanban-cols');
    if (!/Mobi|Android/i.test(navigator.userAgent)) {
        new Sortable(kanbanCols, {
            group: 'kanban-columns',
            animation: 300,
            onEnd: saveColumnOrder,
            scroll: true,
            ghostClass: "custom-ghost-class",  // Class name for the drop placeholder
            chosenClass: "custom-chosen-class",  // Class name for the chosen item
            filter: '.kanban-first-col'
        });
    }
};

const saveColumnOrder = () => {
    const kanbanCols = document.querySelectorAll('.kanban-col');
    const columnIds = [];

    kanbanCols.forEach((col) => {
        columnIds.push(col.dataset.colId);
    });

    const kanbanId = document.querySelector('.kanban').dataset.id;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch(`/kanbans/${kanbanId}/sort_columns`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({columnIds: columnIds}),
    })
        .then((response) => {
            if (response.ok) {
                console.log('Column order saved successfully.');
            } else {
                throw new Error('Error saving column order.');
            }
        })
        .catch((error) => {
            console.error(error);
        });
};

export {initColumnSortable};
