import consumer from "./consumer";

window.subscribeToLogsChannel = function (currentUserId) {
    const identifier = JSON.stringify({channel: "LogsChannel"});
    if (!consumer.subscriptions.findAll(identifier).length) {
        consumer.subscriptions.create({channel: "LogsChannel"}, {

                connected() {
                    var feedbackElement = $("#logs");
                    feedbackElement.html("");
                    console.log("connected to LogsChannel" + currentUserId)
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {
                    // Called when there's incoming data on the websocket for this channel
                    const feedbackElement = $("#logs");
                    feedbackElement.append('<br>' + data);
                    feedbackElement.scrollTop(feedbackElement[0].scrollHeight);
                }
            }
        );
    }
}