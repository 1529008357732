import Sortable from 'sortablejs';
import Rails from "@rails/ujs";
const initSortableTable = () => {
    const sortableHeaderRow = document.getElementById('sortable-header-row');

    new Sortable(sortableHeaderRow, {
        animation: 150,
        handle: '.bi-grip-vertical',
        onEnd: saveColumnOrder,
        filter: '.ignore-th',
        ghostClass: "custom-ghost-class",  // Class name for the drop placeholder
        chosenClass: "custom-chosen-class",  // Class name for the chosen item
        dragClass: "custom-drag-class",
        onUpdate: sortTableCells
    });
};

const sortTableCells = () => {
    const sortableHeaderRow = document.getElementById('sortable-header-row');
    const thElements = sortableHeaderRow.querySelectorAll('th');
    const table = document.querySelector('.sortable-columns');
    const rows = table.querySelectorAll('tr');

    Array.from(rows).forEach((row) => {
        const tdElements = row.querySelectorAll('td');
        const tdOrder = Array.from(thElements).map((thElement) => {
            const fieldId = thElement.dataset.fieldId;
            const tdElement = row.querySelector(`td[data-field-id="${fieldId}"]`);
            return tdElement;
        });

        tdOrder.forEach((tdElement) => {
            if (tdElement && tdElement.parentElement) {
                tdElement.parentElement.appendChild(tdElement);
            }
        });
    });
};


const saveColumnOrder = () => {
    const sortableHeaderRow = document.getElementById('sortable-header-row');
    const thElements = sortableHeaderRow.querySelectorAll('th');

    const columnOrder = Array.from(thElements).map((thElement) => {
        return thElement.dataset.fieldId;
    });

    const contactTypeId = document.getElementById('sortable-header-row').dataset.id;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch('/contact_types/${contactTypeId}/sort_fields', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({fieldIds: columnOrder}),
    })
        .then((response) => {
            if (response.ok) {
                console.log('Field order saved successfully.');
            } else {
                throw new Error('Error saving field order.');
            }
        })
        .catch((error) => {
            console.error(error);
        });
};

export { initSortableTable }
